<div class="middle-holder">
  <div class="middle">
    <div [routerLink]="['/home']" class="logo">
      Certifikat
    </div>
    <div class="spb-filler"></div>

    <div *ngIf="(configService.currentUser$ | async) as user" class="logged-in">
      <span>{{user.name}}</span>
      <div *ngIf="user.name" class="middle-menu">
        <div>
          <button [matMenuTriggerFor]="menu" mat-button>
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="logout()" mat-menu-item>Logga ut</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
