<div class="spb-holder">
  <spb-header></spb-header>
  <div class="spb-plain-content">
    <router-outlet></router-outlet>
  </div>
  <div class="spb-filler"></div>
  <spb-footer
    [license]="true"
    [version]="version"
    copyrightYears="2023"
  ></spb-footer>
</div>

