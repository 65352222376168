import {Component} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {Router} from '@angular/router'


@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(
    public configService: ConfigService,
    private router: Router
  ) {
  }

  public logout(): void {
    this.configService.resetToken().subscribe({
      next: () => this.router.navigate(['login'])
    })
  }
}
