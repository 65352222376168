import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {HelperService, SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../environments/environment'
import {ACCESS_TOKEN_NAME, ADMIN_ROLE} from '../application/data-types'

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * Tells if the logged-in user can use the application
   */
  isUser: boolean
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState$: Observable<SpbConfiguration>
  /**
   * Publish and subscribe to the currently logged-in user here...
   */
  public currentUser$: Observable<SparbankenUser | null>

  private pCurrentUser$: ReplaySubject<SparbankenUser | null> = new ReplaySubject<SparbankenUser | null>(1)

  /**
   * Publish and subscribe to the current SpbConfiguration here...
   */
  private currentConfig: SpbConfiguration = {isUser: false}

  private pConfigState$: BehaviorSubject<SpbConfiguration> = new BehaviorSubject<SpbConfiguration>({isUser: false})

  constructor(
    private helperService: HelperService,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.configState$ = this.pConfigState$.asObservable()
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const token = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    this.setToken(token)
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string | null): void {
    const payload = HelperService.GetTokenPayload(token)
    if (payload === null) {
      this.resetToken().subscribe()
      return
    }

    this.currentConfig = {
      token,
      isUser: payload.roles.indexOf(ADMIN_ROLE) !== -1
    }
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token as string)
    this.helperService.getCurrentUser(environment.authServiceUrl).subscribe({
      next: (user: SparbankenUser) => this.pCurrentUser$.next(user)
    })
    this.pConfigState$.next(this.currentConfig)
  }

  /**
   * Reset what ever access token we might have had, used when logging out
   */
  public resetToken(): Observable<void> {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {isUser: false}
    this.pCurrentUser$.next({name: '', roles: []} as any) // Lame reset
    this.pConfigState$.next(this.currentConfig)
    return of(undefined)
  }

  public getAccessToken(): string | null {
    return HelperService.ValidateToken(this.currentConfig.token)
  }
}
